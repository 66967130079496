import React, {useState, useRef} from 'react'
import {If} from 'babel-plugin-jsx-control-statements'
import {graphql} from 'gatsby'
import PropTypes from 'prop-types'
import {isEmpty, map, mapValues} from 'lodash-es'
import useLang from '../hooks/useLang'
import ServiceHero from '../components/molecules/ServiceHero'
import DetailsContainer from '../components/molecules/Details/DetailsContainer'
import PackagesContainer from '../components/molecules/Packages/PackagesContainer'
import SectionCallToAction from '../components/molecules/SectionCallToAction'
import Navbar from '../components/containers/Navbar'
import AppShell from '../components/containers/AppShell'
import SideTitleSection from '../components/containers/SideTitleSection'
import ReferenceRelation from '../components/molecules/ReferenceRelation'
import CalculatorContainer from '../components/molecules/Calculator/CalculatorContainer'


const Service = ({data, pageContext: {lang: pageLang}}) => {
  const calculatorElRef = useRef(null)
  const [calculatorVisibility, setCalculatorVisibility] = useState(false)
  const [calculatorPreselection, setCalculatorPreselection] = useState('')
  const langToSlug = mapValues(data?.markdownRemark.frontmatter, 'slug')
  const {lang} = useLang(pageLang, langToSlug)
  const pageData = data?.markdownRemark.frontmatter[lang]
  const references = map(data?.references.edges, ({node}) => node.frontmatter[lang])

  const handlePackageButtonClick = (preselection) => {
    setCalculatorVisibility(true)
    setCalculatorPreselection(preselection)
    calculatorElRef?.current.scrollIntoView()
  }

  return (
    <AppShell title={pageData.relation.title} lang={lang} langToSlug={langToSlug} seo={pageData.seo}>
      <Navbar
          title={pageData.returnButton.text}
          titleLink={pageData.returnButton.link}
          lang={lang}
          langToSlug={langToSlug}
      />
      <ServiceHero
          title={pageData.hero.title}
          providers={pageData.hero.providers}
          subTitle={pageData.hero.subtitle}
          description={pageData.hero.description}
          tags={pageData.hero.tags}
          tagsText={pageData.hero.tagsText}
          image={pageData.hero.featuredImage}
      />
      <main>
        <If condition={!isEmpty(pageData.packages)}>
          <SideTitleSection
              title={pageData.packages.title}
              mobileTitleVisible
              mobileFadedSideText
              fadedSideText={pageData.packages.sideText}
          >
            <PackagesContainer content={pageData.packages.entities} onClick={handlePackageButtonClick} />
          </SideTitleSection>
        </If>
        <CalculatorContainer
            content={pageData.calculator}
            isVisible={calculatorVisibility}
            refProp={calculatorElRef}
            lang={lang}
            preselection={calculatorPreselection}
        />
        <SideTitleSection
            title={pageData.contentwrap?.title}
            mobileTitleVisible
            mobileFadedSideText
            fadedSideText={pageData.contentwrap?.sideText}
        >
          <DetailsContainer content={pageData.content} withLeftOffset />
        </SideTitleSection>
        <SectionCallToAction
            mobileCTAVisible
            link={pageData.callToAction.link}
            linkText={pageData.callToAction.linkText}
        >
          {pageData.callToAction.text}
        </SectionCallToAction>
        <SideTitleSection
            title={pageData.references.title}
            mobileTitleVisible
            mobileFadedSideText
            fadedSideText={pageData.references.sideText}
        >
          <ReferenceRelation
              lang={lang}
              logos={pageData.references.logos}
              allReferencesText={pageData.references.allReferencesText}
              allReferencesLink={pageData.references.allReferencesLink}
              references={references}
          />
        </SideTitleSection>
      </main>
    </AppShell>
  )
}

export const data = graphql`
  query Service($id: String!, $references: [String!]) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        ...ServicePageFragment
      }
    }
    references: allMarkdownRemark (
      filter: {frontmatter: {cs: {slug: {in: $references}}}}
    ) {
      edges {
        node {
          frontmatter {
            ...ReferencePageFragment
          }
        }
      }
    }
  }
`

Service.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    lang: PropTypes.string.isRequired,
  }).isRequired,
}

export default Service
