import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {ChevronIcon} from '../../../assets/icons'


const IconWrapper = styled.span`
  display: flex;
  position: absolute;
  top: 50%;
  right: -0.875rem;
  justify-content: center;
  transform: translateY(-50%);
  transition: ${({theme}) => `
    opacity ${theme.global.duration} ease-in,
    transform ${theme.global.duration} ease-in
  `};
  cursor: none;
  pointer-events: none;
`

const StyledButton = styled('button')`
  position: relative;
  margin: 0;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0 1rem 0 0;
  font-weight: 700;
`

const StyledChevron = styled(ChevronIcon).withConfig({shouldForwardProp: (prop, defaultValidatorFn) => (
  !['arrowOrientation'].includes(prop) && defaultValidatorFn(prop)
)})`
    transform: rotate(-90deg);

    ${({arrowOrientation}) => arrowOrientation === 'down' && css`
      transform: rotate(90deg);
      padding-left: 0;
    `}
  `

const ToggleButton = ({onClick, isOpen, children, ...props}) => {
  return (
    <StyledButton
        as="button"
        onClick={onClick}
        {...props}
    >
      {children}
      <IconWrapper>
        <StyledChevron arrowOrientation={isOpen ? 'up' : 'down'} />
      </IconWrapper>
    </StyledButton>
  )
}

ToggleButton.defaultProps = {
  isOpen: false,
}

ToggleButton.propTypes = {
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default ToggleButton
