import React from 'react'
import PropTypes from 'prop-types'
import {For, If} from 'babel-plugin-jsx-control-statements'
import styled from 'styled-components'
import {map} from 'lodash-es'
import theme from '../../../theme/theme'
import ColorFeatherCheckIcon from '../Icons/ColorFeatherCheckIcon'


const StyledRadioSpan = styled.span`
  display: inline-block;
  margin-right: 1.071428rem;
  margin-bottom: 1.071428rem;
  border: 1px solid ${theme.colors.lightGray};
  border-radius: 1.071428rem;
  background: ${theme.colors.lightGray};
  cursor: pointer;
  padding: 0.071428rem 1.071428rem;
  line-height: 1.857142;
  letter-spacing: 0.025em;
  font-size: 1rem;
  font-weight: ${theme.components.typography.weight.medium};

  &:last-child {
    margin-right: 0;
  }

  &.selected {
    background-color: ${theme.colors.white};
    font-weight: ${theme.components.typography.weight.semiBold};
  }

  svg {
    margin-right: 0.642857rem;
  }
`

const CalculatorRadio = ({options, selected, onChange, ...props}) => {
  const calculatorOptions = map(options, (oContent, index) => ({...oContent, index}))

  const handleChange = (optionIndex) => {
    const clickedValue = calculatorOptions[optionIndex].title
    const clickedKey = calculatorOptions[optionIndex].key
    if (clickedValue !== selected && clickedKey !== selected) {
      if (onChange) {
        onChange(
          clickedValue,
          calculatorOptions[optionIndex].price,
          calculatorOptions[optionIndex].isProcentual,
          calculatorOptions[optionIndex].note)
      }
    }
  }

  return (
    <div {...props}>
      <For each="optionData" of={calculatorOptions}>
        <StyledRadioSpan
            key={optionData.index}
            onClick={() => handleChange(optionData.index)}
            className={selected === optionData.title || selected === optionData.key ? 'selected' : ''}
        >
          <If condition={selected === optionData.title || selected === optionData.key}>
            <ColorFeatherCheckIcon color={theme.colors.primary} />
          </If>
          {optionData.title}
          <If condition={optionData.note}>
            <span>*</span>
          </If>
        </StyledRadioSpan>
      </For>
    </div>
  )
}

CalculatorRadio.defaultProps = {
  selected: '',
}

CalculatorRadio.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.string,
  onChange: PropTypes.func,
}

export default CalculatorRadio
