import {useEffect, useState} from 'react'
import theme from '../theme/theme'


const useMediaTier = (media) => {
  const [mediaMatches, setMediaMatches] = useState(null)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const mediaQuery = window.matchMedia(`(max-width: ${theme.global.media[media]}px)`)
      setMediaMatches(mediaQuery.matches)
      mediaQuery.addListener((mediaQuery) => setMediaMatches(mediaQuery.matches))
    }
  }, [media])
  return {mediaMatches}
}
export default useMediaTier
