import React from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'
import useMediaTier from '../../../hooks/useMediaTier'
import theme from '../../../theme/theme'
import Typography from '../../atoms/Typography'


const StyledDetailsWrapper = styled.div`
  ${media.up('mobile')(css`
    position: relative;

    &:not(:first-child) {
      &::before {
        position: absolute;
        left: 1.5rem;
        bottom: 6rem;
        height: 3rem;
        border: 1px solid ${theme.colors.gray89};
        content: '';
      }
    }
`)}
`

const StyledSummary = styled.summary`
  display: flex;
  align-items: center;
  padding-bottom: 3rem;
  list-style: none;

  ::-webkit-details-marker {
    display: none;
  }

  ${media.down('mobile')(css`
    padding: 2rem 0;
  `)}
`

const StyledNumberContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({isActive}) => isActive ? '0' : `1px solid ${theme.colors.gray89}`};
  border-radius: 50%;
  background-color: ${({isActive}) => isActive ? theme.colors.primary : 'transparent'};
  cursor: pointer;
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  min-height: 3rem;
  color: ${({isActive}) => isActive ? theme.colors.textInverted : theme.colors.text};
`

const StyledTitle = styled(Typography)`
  opacity: ${({isActive}) => isActive ? '1' : '0.15'};
  margin-left: 5rem;
  cursor: pointer;

  ${media.down('mobile')(css`
    margin-left: 2rem;
  `)}
`

const StyledDescription = styled.div`
  border: 1px solid ${theme.colors.gray89};
  background-color: ${theme.colors.gray98};
  padding: 1.5rem 2.5rem;

  ul {
    &:not(:first-child) {
      padding-top: 3rem;
    }
  }

  ${media.down('mobile')(css`
    h4 {
      padding-bottom: 1rem;
    }

    ul {
      &:not(:first-child) {
        padding-top: 1rem;
      }
    }
  `)}
`

const Details = ({isActive, title, index, onClick, children}) => {
  const {mediaMatches: isMobile} = useMediaTier('mobile')

  const handleOpen = (e) => {
    e.preventDefault()
    return isActive
  }

  return (
    <StyledDetailsWrapper>
      <details open={isActive && isMobile} onClick={(e) => handleOpen(e)}>
        <StyledSummary onClick={onClick}>
          <StyledNumberContainer isActive={isActive}>
            <Typography type="span" bold>{index}</Typography>
          </StyledNumberContainer>
          <StyledTitle variant="h2" type="h3" isActive={isActive}>{title}</StyledTitle>
        </StyledSummary>
        <StyledDescription>
          {children}
        </StyledDescription>
      </details>
    </StyledDetailsWrapper>
  )
}

Details.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Details
