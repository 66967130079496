import React, {useState} from 'react'
import {For, If} from 'babel-plugin-jsx-control-statements'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {map, isEmpty} from 'lodash-es'
import theme from '../../../theme/theme'
import {media, convertMDContent} from '../../../utils'
import Typography from '../../atoms/Typography'
import Details from '.'


const StyledAccordionContainer = styled.div`
  display: flex;
  padding: ${({withIndentation}) => withIndentation ? '6rem 6rem' : '1rem 6rem'};

  ${media.up('mobile')(({withLeftOffset}) => withLeftOffset && css`
    margin-left: 6rem;
    margin-top: 6rem;
    min-height: 25rem;
  `)}

  ${media.down('mobile')(css`
    padding: 3rem 1.5rem;
  `)}
`

const StyledDetailsContainer = styled.div`
  padding-top: ${({withIndentation}) => withIndentation ? '2.75rem' : '0'};
  width: 50%;

  ${media.down('mobile')(css`
    padding-top: 0;
    width: 100%;
  `)}
`

const StyledDesktopDescriptionContainer = styled.div`
  width: 50%;

  ${media.down('mobile')(css`
    display: none;
  `)}
`

const StyledDescription = styled(Typography)`
  border: 1px solid ${theme.colors.gray89};
  background-color: ${theme.colors.gray98};
  padding: 1.5rem 2.5rem;

  ul {
    &:not(:first-child) {
      padding-top: 1rem;
    }
  }

  p {
    padding-bottom: 0.75rem;
  }
`

const DetailsContainer = ({content, withIndentation, withLeftOffset}) => {
  const [active, setActive] = useState(0)
  const detailsContents = map(content, (detailsContents, index) => ({...detailsContents, index}))

  const handleActiveContent = (index) => {
    if (active !== index) {
      setActive(index)
    }
  }

  return (
    <If condition={!isEmpty(detailsContents)}>
      <StyledAccordionContainer withIndentation={withIndentation} withLeftOffset={withLeftOffset}>
        <StyledDetailsContainer withIndentation={withIndentation}>
          <For each="detailsContent" of={detailsContents}>
            <Details
                key={detailsContent.index}
                onClick={() => handleActiveContent(detailsContent.index)}
                isActive={detailsContent.index === active}
                title={detailsContent.title}
                index={detailsContent.index + 1}
            >
              <Typography
                  type="div"
                  dangerouslySetInnerHTML={{__html: convertMDContent(detailsContent.description)}}
              />
            </Details>
          </For>
        </StyledDetailsContainer>
        <StyledDesktopDescriptionContainer>
          <StyledDescription
              type="div"
              dangerouslySetInnerHTML={{__html: convertMDContent(detailsContents[active]?.description)}}
          />
        </StyledDesktopDescriptionContainer>
      </StyledAccordionContainer>
    </If>
  )
}

DetailsContainer.defaultProps = {
  withIndentation: false,
  withLeftOffset: false,
}

DetailsContainer.propTypes = {
  content: PropTypes.array.isRequired,
  withIndentation: PropTypes.bool,
  withLeftOffset: PropTypes.bool,
}

export default DetailsContainer
