/* eslint-disable max-len */
import React, {useState} from 'react'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import theme from '../../../theme/theme'
import {media, convertMDContent} from '../../../utils'
import Typography from '../../atoms/Typography'
import SecondaryButton from '../../atoms/SecondaryButton'
import ToggleButton from '../../atoms/ToggleButton'


const StyledPackageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2.45rem;
  border: 1px solid ${theme.colors.border};
  border-radius: 0.24rem;
  background-color: ${theme.colors.gray98};
  padding: 3.89rem 3.25rem 3.5714rem;

  ${media.down('mobile')(css`
    padding: 1.55rem;
    margin-top: 3.25rem;
  `)}

  ${media.up('mobile')((props) => props.isHighlighted && css`
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
    margin-top: 0;
    padding-top: 6.34rem;
  `)}

  ${media.down('mobile')((props) => props.isHighlighted && css`
    .theme--light{
      background: ${theme.colors.primary};
      color: ${theme.colors.textInverted};
    }
  `)}

  .package-content {
    ul {
      li {
        padding: 0.42857rem 0;
        line-height: 1.85714;
      }

      li::before {
        padding-right: 0.9rem;
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.121' height='9.373' viewBox='0 0 13.121 9.373'%3E%3Cpath d='M17,9,9.438,16.563,6,13.125' transform='translate(-4.939 -7.939)' fill='none' stroke='%23e02b36' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E%0A");

        ${media.up('mobile')(css`
          .is-highlighted& {
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.121' height='9.373' viewBox='0 0 13.121 9.373'%3E%3Cpath d='M17,9,9.438,16.563,6,13.125' transform='translate(-4.939 -7.939)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/svg%3E%0A");
          }
        `)}
      }
    }
  }
`

const StyledPackageHeadContainer = styled.div`
  margin-bottom: 3.14rem;
  border-bottom: 0.16rem solid ${theme.colors.secondary}1A;
  padding-bottom: 3.3rem;

  ${media.down('mobile')(css`
    padding-bottom: 1.5rem;
  `)}

  ${media.up('mobile')(css`
    .is-highlighted & {
      border-bottom-color: ${theme.colors.white}4C;
    }
  `)}
`

const StyledPackageBodyContainer = styled.div`
  margin-bottom: 3.75rem;

  ${media.down('mobile')(css`
    margin-bottom: 1.75rem;
  `)}
`

const StyledPackageFootContainer = styled.div`
  text-align: center;
`

const StyledTitle = styled(Typography)`
  margin-bottom: 0.643rem;
  color: ${theme.colors.primary};

  ${media.up('mobile')(css`
    .is-highlighted & {
      color: ${theme.colors.white};
    }
  `)}
`

const StyledPriceSuffix = styled(Typography)`
  position: relative;
  top: 1px;
  margin-left: 1rem;
  line-height: 2;
  letter-spacing: 0.025em;
  font-size: ${theme.components.typography.variants.span};
  font-weight: ${theme.components.typography.weight.semiBold};
`

const StyledPrice = styled(Typography)`
  line-height: 1.4545;
  letter-spacing: 0.025em;
  font-size: ${theme.components.typography.variants.h3};
  font-weight: ${theme.components.typography.weight.semiBold};
`

const StyledDescriptionTitleContainer = styled.div`
  margin-bottom: 1rem;

  ${media.down('mobile')(css`
    .description-title--plain {
      display: none;
    }
  `)}

  ${media.up('mobile')(css`
    .description-title--button {
      display: none;
    }
  `)}
`

const StyledPackageContentWrap = styled.div`
  ${media.down('mobile')(css`
    .package-description-wrap:not(.active) {
      display: none;
    }
  `)}
`

const StyledBoldText = styled.span`
  font-weight: 700;
`

const PackageContainer = ({details, onClick}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handlePackageButton = () => {
    setIsOpen((prevValue) => !prevValue)
  }

  return (
    <StyledPackageContainer
        isHighlighted={details.highlighted}
        className={details.highlighted ? 'is-highlighted' : ''}
    >
      <StyledPackageBodyContainer>
        <StyledPackageHeadContainer>
          <StyledTitle variant="h3" type="h5">{details.title}</StyledTitle>
          <StyledPrice type="span">{details.price}</StyledPrice>
          <StyledPriceSuffix type="span">{details.priceSuffix}</StyledPriceSuffix>
        </StyledPackageHeadContainer>
        <StyledPackageContentWrap className="package-content">
          <StyledDescriptionTitleContainer>
            <ToggleButton className="description-title--button" isOpen={isOpen} onClick={handlePackageButton}>{details.descriptionTitle ?? 'Package contains'}</ToggleButton>
            <StyledBoldText className="description-title--plain">{details.descriptionTitle ?? 'Package contains'}</StyledBoldText>
          </StyledDescriptionTitleContainer>
          <Typography
              type="div"
              className={`package-description-wrap ${isOpen ? 'active' : ''}`}
              dangerouslySetInnerHTML={{__html: convertMDContent(details.description)}}
          />
        </StyledPackageContentWrap>
      </StyledPackageBodyContainer>
      <StyledPackageFootContainer>
        <SecondaryButton theme={details.highlighted ? 'light' : 'dark'} onClick={() => onClick(details.preselection ?? '')}>{details.ctaLabel}</SecondaryButton>
      </StyledPackageFootContainer>
    </StyledPackageContainer>
  )
}

PackageContainer.propTypes = {
  details: PropTypes.object.isRequired,
  onClick: PropTypes.func,
}

export default PackageContainer
