import React from 'react'
import {For, If} from 'babel-plugin-jsx-control-statements'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {map, isEmpty} from 'lodash-es'
import {media} from '../../../utils'
import PackageContainer from './PackageContainer'


const StyledPackagesContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 6.1788rem 6.125rem 5rem 12.25rem;

  ${media.down('mobile')(css`
    margin: 1.55rem;
    display: block;
  `)}
`

const PackagesContainer = ({content, onClick, withIndentation}) => {
  const packagesContents = map(content, (packagesContents, index) => ({...packagesContents, index}))

  return (
    <If condition={!isEmpty(packagesContents)}>
      <StyledPackagesContainer withIndentation={withIndentation}>
        <For each="packageData" of={packagesContents}>
          <PackageContainer details={packageData} key={packageData.index} onClick={onClick} />
        </For>
      </StyledPackagesContainer>
    </If>
  )
}

PackagesContainer.defaultProps = {
  withIndentation: false,
}

PackagesContainer.propTypes = {
  content: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  withIndentation: PropTypes.bool,
}

export default PackagesContainer
