import React, {useState, useEffect, useRef} from 'react'
import {If, For} from 'babel-plugin-jsx-control-statements'
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {isEmpty, isArray} from 'lodash-es'
import countries from '../../../../content/settings/countries.json'
import mailchimpData from '../../../../content/settings/mailchimp.json'
import {media, sendForm, convertMDContent, encode} from '../../../utils'
import theme from '../../../theme/theme'
import Typography from '../../atoms/Typography'
import TextInput from '../../inputs/TextInput'
import SubmitButton from '../../inputs/SubmitButton'
import PhoneInput from '../../inputs/PhoneInput'
import TextArea from '../../inputs/TextArea'
import CalculatorRadio from '../../atoms/CalculatorRadio'


const StyledTitle = styled(Typography)`
  margin-bottom: 2.214285rem;
  text-align: center;
  line-height: 1.428571;
`

const StyledCalculatorContainer = styled.div`
  margin: 0 auto;
  padding-top: 0.785714rem;
  padding-bottom: 8.5rem;
  width: 100%;
  max-width: 36.603221vw;

  ${media.down('mobile')(css`
    max-width: 100%;
    padding: 0 1.55rem;
    margin: 6.4rem auto 4rem;
  `)}
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  width: 100%;

  ${media.down('mobile')(css`
    padding-bottom: 6.5rem;
  `)}
`

const StyledInputContainer = styled.div`
  padding-bottom: 1rem;
`

const StyledButton = styled(SubmitButton)`
  ${media.down('mobile')(css`
    height: 4.5rem;
  `)}
`

const StyledRowTitle = styled(Typography)`
  margin-bottom: 1.571428rem;
`

const StyledRowRadio = styled(CalculatorRadio)`
  margin-bottom: 2.928572rem;
`

const StyledPackageCost = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${theme.colors.lightGray};
  border-radius: 0.214285rem;
  background: ${theme.colors.gray98};
  padding: 1.642857rem 1.8125rem 1.428571rem 1.8125rem;

  .package-cost {
    &__label {
      line-height: 2.285714;
      letter-spacing: 0.025em;
      font-size: 1rem;

      ${media.down('mobile')(css`
        display: block;
      `)}
    }

    &__price {
      line-height: 1.733333;
      letter-spacing: 0.025em;
      font-size: 2.142857rem;
      font-weight: ${theme.components.typography.weight.bold};

      ${media.down('mobile')(css`
        display: block;
      `)}
    }
  }

  ${media.down('mobile')(css`
    display: block;
    text-align: center;
  `)}
`

const StyledFormNote = styled.div`
  opacity: 0.5;
  line-height: 2;
  letter-spacing: 0.025em;
  color: ${theme.colors.secondary};
  font-size: 0.857142rem;
`

const StyledFormNoteContainer = styled.div`
  ${StyledFormNote} {
    &:first-child {
      margin-top: 0.785714rem;
    }
  }
`

const StyledFormMessage = styled.div`
  text-align: center;
  color: green;
  font-size: 1.5rem;

  &.active {
    margin-bottom: 7rem;
  }
`

const StyledFormErrorMessage = styled.div`
  margin-top: 2rem;
  color: red;
`

const StyledContactFormTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.785714rem;
  margin-bottom: 1.571428rem;
  border-top: 1px solid ${theme.colors.lightGray};
  padding-top: 2.5rem;

  ${media.down('mobile')(css`
    span {
      display: none;
    }
  `)}
`

const StyledContactFormTitle = styled(Typography)`
  opacity: 1;
`

const StyledFormSubmitContainer = styled.div`
  padding-top: 2rem;
`

const StyledInformationTypography = styled(Typography)`
  a {
    text-decoration: underline;
    color: ${theme.colors.text};
  }
`

const CalculatorContainer = ({content, lang, isVisible, refProp, preselection, ...props}) => {
  const initialData = {
    'fullname': '',
    'email': '',
    'prefix': countries.countries[56].dial_code,
    'number': '',
    'message': '',
    'bot-field': '',
  }

  const successMessageElRef = useRef(null)

  const [formErrorMessage, setFormErrorMessage] = useState('')
  const [formData, setFormData] = useState(initialData)
  const [formCalculationData, setFormCalculationData] = useState({})
  const [formNotesData, setFormNotesData] = useState({})
  const [showMessage, setShowMessage] = useState(false)
  const [calculatedPackageCost, setCalculatedPackageCost] = useState(0)

  const preselectionData = {}
  preselection.split(',').forEach((el) => {
    const elData = el.toString().split(':')
    if (elData.length === 2) {
      preselectionData[elData[0]] = elData[1]
    }
  })

  const convertToKey = (text) => {
    const from = 'ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;' // eslint-disable-line
    const to = 'AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------' // eslint-disable-line
    let str = text.trim().replace(/ /g, '-').toLowerCase()
    for (let i = 0; i < from.length; i += 1) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }
    return str.replace(/[^\w-]+/g, '')
  }

  const sendToMailchimp = async () => {
    const chimpUrl = (mailchimpData[lang]?.packageCalculator?.chimpUrl ?? '').toString().trim()
    const chimpUser = (mailchimpData[lang]?.packageCalculator?.chimpUser ?? '').toString().trim()
    const listId = (mailchimpData[lang]?.packageCalculator?.listId ?? '').toString().trim()
    if (chimpUrl === '' || chimpUser === '' || listId === '') {
      return
    }

    const data = {
      u: chimpUser,
      id: listId,
      EMAIL: formData.email ?? '',
      FULLNAME: formData.fullname ?? '',
      PHONE: (formData.prefix ?? '') + (formData.number ?? ''),
      FORMTYPE: content.identifier ?? '',
      tags: content.mailchimpTags ?? '',
    }

    return await fetch(chimpUrl, {
      method: 'POST',
      body: encode(data),
      mode: 'no-cors',
      credentials: 'omit',
      cache: 'no-cache',
    })
  }

  const handleChange = (name, value) => setFormData((prevValues) => ({...prevValues, [name]: value}))

  const handleSubmit = async (e) => {
    e.preventDefault()

    const calculatorData = []
    let isFormError = false
    // Validate form
    if (isArray(content.entities) && !isEmpty(content.entities)) {
      content.entities.forEach((calcEntity) => {
        const entityKey = convertToKey(calcEntity.title)
        if (isEmpty(formData[entityKey])) {
          isFormError = true
        } else {
          calculatorData.push(`${calcEntity.title.toString()}: ${formData[entityKey].toString()}`)
        }
      })
    }

    if (isFormError) {
      setFormErrorMessage(content.form.msgUncompletedPackage ?? 'Complete package selection')
      return
    }

    setFormErrorMessage('')

    await sendForm({
      'form-name': 'packageCalculator',
      'calculator-type': content.identifier ?? '',
      'calculator-data': calculatorData.join(' | '),
      ...formData,
    })
      .then(() => {
        setShowMessage(true)
        sendToMailchimp()
      })
      .catch((error) => {
        console.error(error)
        setFormErrorMessage('There was an error')
      })
  }

  const calculatePackageCost = () => {
    let calculatedPrice = 0
    let modifier = 1
    Object.keys(formCalculationData).forEach((key) => {
      const value = parseInt(formCalculationData[key].price ?? 0, 10)
      const isProcentual = Boolean(formCalculationData[key].isProcentual)
      if (isProcentual) {
        // Set modifier to be applied after all non-procentual values are handled
        modifier *= (1 + (value / 100))
      } else {
        calculatedPrice += value
      }
    })

    // Apply modifier
    calculatedPrice *= modifier

    setCalculatedPackageCost(calculatedPrice > 0 ? Math.round(calculatedPrice) : 0)
  }

  const handleRadioChange = (newValue, fieldText, price, isProcentual, note) => {
    const fieldKey = convertToKey(fieldText)
    setFormData((prevValues) => ({...prevValues, [fieldKey]: newValue}))
    setFormCalculationData((prevValues) => ({...prevValues, [fieldKey]: {price, isProcentual}}))
    setFormNotesData((prevValue) => ({...prevValue, [fieldKey]: note}))
  }

  const formatPrice = (price) => {
    return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')},-`
  }

  useEffect(() => {
    calculatePackageCost()
  }, [formData])

  useEffect(() => {
    if (showMessage) {
      successMessageElRef?.current.scrollIntoView()
    }
  }, [showMessage])

  const determineSelectedCalcRadio = (key, title) => {
    const fieldKey = convertToKey(title)

    // Find by key
    const foundValue = (key in formData) ? formData[key] : ''
    if (foundValue !== '') {
      return foundValue
    }

    // Find by title
    return (fieldKey in formData) ? formData[fieldKey] : ''
  }

  useEffect(() => {
    // Reset form
    const cleanformData = {
      fullname: '',
      email: '',
      prefix: countries.countries[56].dial_code,
      number: '',
      message: '',
    }
    const cleanCalculatorData = {}
    const cleanFormNotes = {}

    Object.keys(preselectionData).every((elKey) => {
      const elVal = preselectionData[elKey].toString()
      if (elVal === '') {
        return true
      }

      content.entities.every((entity) => {
        if (entity.key === elKey) {
          entity.options.every((entityOption) => {
            if (entityOption.key === elVal) {
              const fieldKey = convertToKey(entity.title)
              cleanformData[fieldKey] = entityOption.title
              cleanCalculatorData[fieldKey] = {
                price: entityOption.price,
                isProcentual: entityOption.isProcentual,
              }
              cleanFormNotes[fieldKey] = entityOption.note
              return false
            }
            return true
          })
          return false
        }
        return true
      })
      return true
    })

    setFormData(() => cleanformData)
    setFormCalculationData(() => cleanCalculatorData)
    setFormNotesData(() => cleanFormNotes)
  }, [isVisible, preselection])

  return (
    <div ref={refProp}>
      <If condition={!isEmpty(content) && isVisible}>
        <StyledCalculatorContainer {...props}>
          <StyledTitle variant="h4" type="h4">
            {content.title}
          </StyledTitle>
          <StyledFormMessage ref={successMessageElRef} className={showMessage ? 'active' : ''}>
            <If condition={showMessage}>{content.form.msgSuccess ?? 'Form sent'}</If>
          </StyledFormMessage>
          <If condition={!showMessage}>
            <StyledForm
                name="packageCalculator"
                method="post"
                action="/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
            >
              <input type="hidden" name="form-name" value="packageCalculator" />
              <input type="hidden" name="calculator-type" value={content.identifier ?? ''} />
              <input type="hidden" name="calculator-data" value="" />
              <If condition={!isEmpty(content.entities)}>
                <For each="calculatorEntity" of={content.entities}>
                  <StyledRowTitle variant="h5" type="h5">
                    {calculatorEntity.title}
                  </StyledRowTitle>
                  <If condition={!isEmpty(calculatorEntity.options)}>
                    <StyledRowRadio
                        options={calculatorEntity.options}
                        selected={determineSelectedCalcRadio(calculatorEntity.key ?? '', calculatorEntity.title)}
                        onChange={
                          (value, price, isProcentual, note) => handleRadioChange(
                            value,
                            calculatorEntity.title,
                            price,
                            isProcentual,
                            note)
                        }
                    />
                  </If>
                </For>
              </If>
              <If condition={calculatedPackageCost > 0}>
                <StyledPackageCost>
                  <span className="package-cost__label">{content.resultLabel}</span>
                  <span className="package-cost__price">{formatPrice(calculatedPackageCost)}</span>
                </StyledPackageCost>
              </If>
              <If condition={!isEmpty(formNotesData)}>
                <StyledFormNoteContainer>
                  <For each="formNote" index="formNoteIndex" of={Object.values(formNotesData)}>
                    <If condition={!isEmpty(formNote)}>
                      <StyledFormNote key={formNoteIndex}><span>*</span> {formNote}</StyledFormNote>
                    </If>
                  </For>
                </StyledFormNoteContainer>
              </If>
              <StyledContactFormTitleContainer>
                <StyledContactFormTitle variant="h5" type="h5">
                  {content.form.contactTitle}
                </StyledContactFormTitle>
                <span>{content.form.requiredLabel}</span>
              </StyledContactFormTitleContainer>
              <StyledInputContainer>
                <TextInput
                    name="fullname"
                    label={content.form.fullnameLabel}
                    value={formData?.fullname}
                    onChange={handleChange}
                    required
                />
              </StyledInputContainer>
              <StyledInputContainer>
                <TextInput
                    name="email"
                    type="email"
                    label={content.form.emailLabel}
                    value={formData?.email}
                    onChange={handleChange}
                    required
                />
              </StyledInputContainer>
              <StyledInputContainer>
                <PhoneInput
                    label={content.form.phoneLabel}
                    prefixName="prefix"
                    numberName="number"
                    onChange={handleChange}
                    NumberValue={formData?.number}
                    prefixValue={formData?.prefix}
                    required={content.form.isPhoneRequired}
                />
              </StyledInputContainer>
              <StyledInputContainer>
                <TextArea
                    name="message"
                    label={content.form.noteLabel}
                    value={formData?.message}
                    onChange={handleChange}
                />
              </StyledInputContainer>
              <StyledInformationTypography
                  thin
                  dangerouslySetInnerHTML={{__html: convertMDContent(content.form.information)}}
              />
              <If condition={!isEmpty(formErrorMessage)}>
                <StyledFormErrorMessage>{formErrorMessage}</StyledFormErrorMessage>
              </If>
              <TextInput
                  hidden
                  name="bot-field"
                  value={formData?.['bot-field']}
                  onChange={handleChange}
              />
              <StyledFormSubmitContainer>
                <StyledButton value={content.form.buttonLabel} />
              </StyledFormSubmitContainer>
            </StyledForm>
          </If>
        </StyledCalculatorContainer>
      </If>
    </div>
  )
}

CalculatorContainer.defaultProps = {
  isVisible: false,
  preselection: '',
}

CalculatorContainer.propTypes = {
  content: PropTypes.object.isRequired,
  isVisible: PropTypes.bool,
  refProp: PropTypes.any,
  lang: PropTypes.string.isRequired,
  preselection: PropTypes.string,
}

export default CalculatorContainer
